<template>
  <div class="container">
    <div class="facility">
      <div>
        <a-input placeholder="请输入身份" type="text" v-model="name" />
        <a-button type="primary" @click="search">搜索</a-button>
      </div>
      <a-button type="primary" @click="add"
        v-if="getJurisdictionList.indexOf('authorityManagement-addBtn') > -1">新增</a-button>
    </div>
    <!-- 表格 -->
    <ant-tabel :data="data" :columns="columns" :loading="loading" :status="status" :pagination="pagination"
      @changePaging="changePaging" :operationBtn="operationBtn" @operation="operation" recordKey="accessId"></ant-tabel>
    <a-modal :title="title" :visible="visible" @ok="handleOk" @cancel="handleCancel" v-if="showModal">
      <div class="information">
        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" ref="ruleForm" :model="ruleForm" :rules="rules">
          <div v-for="(item, i) in addDemandtList" :key="i">
            <a-form-model-item :label="item.label" :prop="item.dataIndex">
              <div v-if="item.type == 'input'">
                <a-input v-model="ruleForm[item.dataIndex]" :placeholder="item.placeholder"
                  :type="item.inputType && item.inputType == 'password' ? 'password' : 'text'"></a-input>
              </div>
              <div v-if="item.type == 'tree'">
                <a-tree v-if="showTree" :checkable="true" :tree-data="treeData" :checkedKeys="ruleForm.accessList"
                  v-model="ruleForm.accessList" @check="onCheck"></a-tree>
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import api from "@/api/index.js";
import jurisdictionList from "../../jurisdictionList";
import storage from "../../storage/storage.js";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";
const columns = [
  {
    title: "序号",
    dataIndex: "num",
    key: "num",
    width: 90,
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  { title: "身份", dataIndex: "name", key: "name" },
  { title: "权限", dataIndex: "access_lists", key: "access_lists" },
  { title: "添加时间", dataIndex: "createTime", key: "createTime" },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operation" },
    width: 180,
  },
];

const data = [];
const treeData = [
  {
    title: "医护管理",
    key: "healthcare",
    children: [{ title: "医护管理-查看列表", key: "healthcare-checkList" }],
  },
  {
    title: "患者管理",
    key: "patientmanagement",
    children: [
      {
        title: "患者信息记录",
        key: "patientinformation",
        children: [
          { title: "患者信息记录-查看列表", key: "patientinformation-checkList" },
          {
            title: "患者信息记录-查看统计数据",
            key: "patientinformation-statisticsBtn",
          },
          { title: "患者信息记录-下载数据", key: "patientinformation-downloadBtn" },
        ],
      },
      {
        title: "患者历史操作记录",
        key: "patientinoperation",
        children: [
          {
            title: "患者历史操作记录-查看列表",
            key: "patientinoperation-checkList",
          },
          {
            title: "患者历史操作记录-数据下载",
            key: "patientinoperation-downloadBtn",
          },
        ],
      },
      {
        title: "患者静态信息",
        key: "patientstaticinformation",
        children: [
          {
            title: "患者静态信息-查看列表",
            key: "patientstaticinformation-checkList",
          },
          {
            title: "患者静态信息-数据下载",
            key: "patientstaticinformation-downloadBtn",
          },
        ],
      },
    ],
  },
  {
    title: "数据分析",
    key: "dataanalysis",
    children: [
      {
        title: "血糖质量控制",
        key: "bloodglucosequality",
        children: [
          {
            title: "血糖质量控制-查看列表",
            key: "bloodglucosequality-checkList",
          }
        ],
      },
      {
        title: "医护行为分析",
        key: "behavioranalysis",
        children: [
          {
            title: "医护行为分析-查看列表",
            key: "behavioranalysis-checkList",
          }
        ],
      },
    ],
  },
  {
    title: "系统设置",
    key: "systemSettings",
    children: [
      {
        title: "账号管理",
        key: "accountManagement",
        children: [
          { title: "账号管理-查看列表", key: "accountManagement-checkList" },
          { title: "账号管理-新增", key: "accountManagement-addBtn" },
          { title: "账号管理-编辑", key: "accountManagement-compileBtn" },
          { title: "账号管理-删除", key: "accountManagement-deleteBtn" },
        ],
      },
      {
        title: "权限管理",
        key: "authorityManagement",
        children: [
          { title: "权限管理-查看列表", key: "authorityManagement-checkList" },
          {
            title: "权限管理-新增",
            key: "authorityManagement-addBtn",
          },
          {
            title: "权限管理-编辑",
            key: "authorityManagement-compileBtn",
          },
          {
            title: "权限管理-查看",
            key: "authorityManagement-examineBtn",
          },
          {
            title: "权限管理-删除",
            key: "authorityManagement-deleteBtn",
          },
        ],
      },
    ]
  },
];
export default {
  components: { AntTabel },
  data() {
    return {
      checkList: [
        "accountManagement-checkList",
        "patientinformation-checkList",
        "authorityManagement-checkList",
      ], //权限查看列表
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      getJurisdictionList: "",
      treeData,
      flag: 1,
      jurisdictionList: jurisdictionList,
      allSelectedNodes: [],
      showModal: false,
      showTree: true,
      account: "",
      name: "",
      ruleForm: {
        name: "",
        accessList: [],
      },

      rules: {
        name: [
          {
            required: true,
            message: "请输入身份",
            trigger: "blur",
          },
          { min: 1, max: 20, message: "长度不超过20个字符", trigger: "change" }
        ],
      },
      title: "新增",
      visible: false,
      data,
      columns,
      loading: true,
      status: false,
      routes: [
        {
          path: "index",
          breadcrumbName: "Authority management",
        },
      ],
      // 操作按钮
      operationBtn: [],
      addDemandtList: [
        {
          type: "input",
          label: "身份:",
          dataIndex: "name",
          placeholder: "请输入身份",
        },
        {
          type: "tree",
          label: "权限:",
          dataIndex: "tree",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
      uniqueArr: [], //选择后，确认之前的数组
    };
  },
  mounted() {
    this.getAuthority();
    this.getJurisdiction();
  },
  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
      if (
        this.getJurisdictionList.indexOf("authorityManagement-compileBtn") > 0
      ) {
        this.operationBtn.push({ name: "编辑", type: "compile" });
      }
      if (
        this.getJurisdictionList.indexOf("authorityManagement-examineBtn") > 0
      ) {
        this.operationBtn.push({ name: "查看", type: "examine" });
      }
      if (
        this.getJurisdictionList.indexOf("authorityManagement-deleteBtn") > 0
      ) {
        this.operationBtn.push({ name: "删除", type: "deletes" });
      }
    },
    getAuthority(data) {
      let obj = { name: data, pagination: this.pagination };
      api
        .getAuthority(obj)
        .then((res) => {
          this.pagination.total = res.total;
          this.loading = false;
          this.data = [];
          res.rows.forEach((data) => {
            let access_lists = [];
            for (const i in this.jurisdictionList) {
              for (const j in data.accessList) {
                if (this.jurisdictionList[i].key == data.accessList[j]) {
                  access_lists.push(
                    this.jurisdictionList[i].title + "\xa0  \xa0"
                  );
                }
              }
            }
            data.access_lists = access_lists;
            this.data.push(data);
          });
        })
        .catch(() => { });
    },
    search() {
      if (this.name) {
        this.pagination.current = 1;
      }
      this.getAuthority(this.name);
      this.loading = true;
    },
    add() {
      this.showModal = true;
      (this.ruleForm = {
        name: "",
        accessList: [],
      }),
        (this.visible = true);
      this.showTree = true;
      this.flag = 1;
    },
    handleOk() {
      this.ruleForm.accessList = this.uniqueArr;

      let accountManagement = [];
      let patientinformation = [];
      let authorityManagement = [];
      let patientinoperation = [];
      let patientstaticinformation = [];
      if (this.flag != 3) {
        this.ruleForm.accessList.forEach((item) => {
          if (item.split("-")[0] == "accountManagement") {
            accountManagement.push(item);
          }
          if (item.split("-")[0] == "patientinformation") {
            patientinformation.push(item);
          }
          if (item.split("-")[0] == "authorityManagement") {
            authorityManagement.push(item);
          }
          if (item.split("-")[0] == "patientinoperation") {
            patientinoperation.push(item);
          }
          if (item.split("-")[0] == "patientstaticinformation") {
            patientstaticinformation.push(item);
          }
        });
        //账号
        let account = accountManagement.includes("accountManagement-checkList");
        if (account == false && accountManagement.length > 0) {
          return message.error("请选择添加账号-查看列表");
        }
        // 权限
        let authority = authorityManagement.includes(
          "authorityManagement-checkList"
        );
        if (authority == false && authorityManagement.length > 0) {
          return message.error("请选择添加权限-查看列表");
        }
        // 患者信息记录
        let patientin = patientinformation.includes(
          "patientinformation-checkList"
        );
        if (patientin == false && patientinformation.length > 0) {
          return message.error("请选择添加患者信息记录-查看列表");
        }

        // 患者操作数据记录
        let perations = patientinoperation.includes(
          "patientinoperation-checkList"
        );
        if (perations == false && patientinoperation.length > 0) {
          return message.error("请选择添加患者操作记录-查看列表");
        }

        // 患者静态信息
        let perationsstatic = patientstaticinformation.includes(
          "patientstaticinformation-checkList"
        );
        if (perationsstatic == false && patientstaticinformation.length > 0) {
          return message.error("请选择患者静态信息-查看列表");
        }
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.flag == 1) {
            api.addAuthority(this.ruleForm).then(() => {
              this.visible = false;
              this.showTree = false;
              this.getAuthority();
            });
          } else if (this.flag == 2) {
            api.amendAuthority(this.ruleForm).then(() => {
              this.visible = false;
              this.showTree = false;
              this.getAuthority();
            });
          } else if (this.flag == 3) {
            this.visible = false;
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.showTree = false;
      this.showModal = false;
      this.visible = false;
    },
    //动态操作
    operation(type, data) {
      if (type == "compile") {
        this.compile(data);
      }
      if (type == "examine") {
        this.examine(data);
      }
      if (type == "deletes") {
        this.deletes(data);
      }
    },
    // 编辑
    compile(data) {
      this.showModal = true;
      this.flag = 2;
      this.title = "编辑";
      this.operationData(data);
    },
    // 查看
    examine(data) {
      this.showModal = true;
      this.flag = 3;
      this.title = "查看";
      this.visible = true;
      this.operationData(data);
    },

    // 共同数据
    operationData(data) {
      this.showTree = true;
      this.visible = true;
      let { name, accessList, accessId } = data;
      this.ruleForm.name = name;
      this.ruleForm.accessId = accessId;
      this.ruleForm.accessList = this.getShowList(accessList);
      this.uniqueArr = this.getShowList(accessList);
    },
    getShowList(accessList) {
      //展示的项，去除了父级
      let fatherArr = ['healthcare', "patientmanagement", "patientinformation", "patientinoperation", "patientstaticinformation", "dataanalysis", "bloodglucosequality", "behavioranalysis", "systemSettings", "accountManagement", "authorityManagement"]
      let newArr = accessList.filter(function (item) {
        return fatherArr.every(function (item1) {
          return item !== item1;
        })
      });
      return newArr;
    },
    // 删除
    deletes(data) {
      api.deleteAuthority(data).then((res) => {
        this.getAuthority();
      });
    },
    onCheck(checkedKeys) {
      const selectedNodes = [];

      const traverse = (nodes, parent) => {
        for (const node of nodes) {
          if (checkedKeys.includes(node.key)) {
            selectedNodes.push(node.key);
            if (parent) {
              selectedNodes.push(parent.key);
            }
          }
          if (node.children) {
            traverse(node.children, node);
          }
        }
      };

      traverse(this.treeData);

      this.uniqueArr = [...new Set(selectedNodes)];
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.getAuthority();
    },
  },
};
</script>
<style scoped>
.facility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.facility .ant-input {
  width: 210px;
  height: 32px;
  border-radius: 2px;
}

.facility .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}
</style>
