<template>
  <div class="tabel">
    <a-table :row-selection="status ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange, } : null"
      :columns="columns" :data-source="data" class="components-table-demo-nested" :pagination="false" :loading="loading"
      :rowKey="record => record[recordKey]">
      <!-- :scroll="{ x: 300, y: 600 }" -->
      <template slot="num" slot-scope="text, record, index">
        <span>
          {{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}
        </span>
      </template>
      <template slot="platform" slot-scope="text, record">
        <img v-if="record.avatarUrl" class="portrait" :src="record.avatarUrl" />
      </template>
      <!-- 二维码 -->
      <template slot="qsCode" slot-scope="text, record">
        <img v-if="record.qsCode" class="qsCode" :src="record.qsCode" />
      </template>
      <!-- 账号   权限管理 -->
      <template v-if="operationBtn" slot="operation" slot-scope="text, record">
        <div style="display: flex; flex-wrap: wrap">
          <div v-for="(item, index) in operationBtn" :key="index" style="display: flex;">
            <a-popconfirm v-if="item.name == '删除'" title="你确认删除这条数据吗？" ok-text="确认" cancel-text="取消"
              @confirm="operation(item.type, record)" @cancel="cancel">
              <a style="color:#F21818;">{{ item.name }}</a>
            </a-popconfirm>
            <a v-else style="color:#2468F2" @click="operation(item.type, record)">{{ item.name }}</a>
            <a-divider v-if="index !== operationBtn.length - 1" type="vertical" />
          </div>
        </div>
      </template>

      <template slot="operations" slot-scope="text, record">
        <a v-if="getJurisdictionList.indexOf('patientinformation-statisticsBtn') > -1"
          @click="patient(text, record)">查看统计数据</a>
      </template>
    </a-table>
    <!-- 分页 -->
    <div v-if="!pagination.hidePage" class="pagination">
      <a-pagination @change="handleTableChange" :current="pagination.current" :defaultPageSize="pagination.pageSize"
        :total="pagination.total" show-size-changer show-quick-jumper :page-size-options="pageSizeOptions"
        @showSizeChange="onShowSizeChange" />
    </div>
  </div>
</template>
<script>
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
export default {
  props: {
    data: {},
    columns: {},
    loading: {},
    operationBtn: {
      type: Array,
    },
    status: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
    },
    recordKey: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      getJurisdictionList: "",
      selectedRowKeys: [],
      pageSizeOptions: ['10', '20', '30', '50', '100'],
    };
  },
  mounted() {
    this.getJurisdictionList = storage.getItem("getJurisdiction");
  },
  methods: {
    //动态操作
    operation(type, record) {
      this.$emit("operation", type, record);
    },
    cancel(e) {
    },
    patient(text) {
      let params = {
        patientId: text.patientId
      }
      api.listGlsRecord(params).then((res) => {
        this.$emit("sick", res.data);
        this.$router.push({ query: { text: text.age, patientId: text.patientId } });
      });
    },
    //单击分页
    handleTableChange(page, pageSize) {
      this.$emit("changePaging", page, pageSize);
    },
    //选中
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length > 0) {
        if (!selectedRows[0].flag) {
          this.$emit("pitch", this.selectedRowKeys);
        } else {
          this.$emit("pitch", selectedRows);
        }
      } else {
        this.$emit("pitch", []);
      }
    },
    onShowSizeChange(page, pageSize) {
      this.$emit("changePaging", page, pageSize);
    },
  },
};
</script>
<style scoped>
.tabel {
  display: flex;
  flex-direction: column;
}

.portrait {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.qsCode {
  width: 80px;
  height: 80px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

>>>.ant-table {
  color: #666666;
}

>>>.ant-table-thead>tr>th {
  color: #787878;
}

>>>.ant-table-body {
  overflow: auto;
  max-height: calc(100vh - 210px);
}

>>>.ant-table-thead>tr th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.ant-divider,
.ant-divider-vertical {
  transform: translateY(6px);
}
</style>
